import { createSlice } from "@reduxjs/toolkit";
import { dashboardApi } from "../services/dashboardApi";

const initialState = {
  data: null,
  loading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(dashboardApi.endpoints.getAllUsers.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        dashboardApi.endpoints.getAllUsers.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.data = payload.data;
        }
      )
      .addMatcher(dashboardApi.endpoints.getAllUsers.matchRejected, (state) => {
        state.data = null;
        state.loading = false;
      })
      .addMatcher(
        dashboardApi.endpoints.getActiveUsers.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        dashboardApi.endpoints.getActiveUsers.matchFulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.data = payload.data;
        }
      )
      .addMatcher(
        dashboardApi.endpoints.getActiveUsers.matchRejected,
        (state) => {
          state.data = null;
          state.loading = false;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;

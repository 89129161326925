import React from "react";

import styles from "./styles/AccountLayout.module.scss";

import { Col, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";

import { ShadowContainer } from "../CustomComponents";

export default function AccountLayout({
  hasBackButton = false,
  handleBackButton = () => {},
  children,
}) {
  return (
    <div
      className={styles.AccountLayout}
      style={{
        backgroundImage: `url(/images/account_bg.png)`,
        backgroundSize: "cover",
      }}
    >
      <ShadowContainer />
      <div className={styles.sectionContainer}>
        <Row className={styles.row}>
          <Col md={7}></Col>
          <Col md={5} className={styles.formContainer}>
            <img
              src="/images/logo_name.png"
              alt="Logo"
              width={"200px"}
              className={`${styles.image} ${styles.logo}`}
            />
            <br></br>
            <div className={styles.form}>
              {hasBackButton ? (
                <div
                  className={styles.backButtonContainer}
                  onClick={handleBackButton}
                >
                  <IoIosArrowBack />
                </div>
              ) : (
                ""
              )}
              {children}
            </div>
          </Col>
          {
            // <Col
            // md={6}
            // className={`${styles.mediaContainer}`}
            // >
            // <img src='/images/logo_assets.png' alt='Logo Assets' className={styles.image} />
            // <img src='/images/logo.png' alt="Logo" className={`${styles.image} ${styles.logo}`} />
            // </Col>
          }
        </Row>
      </div>
    </div>
  );
}

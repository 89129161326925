import React, { useEffect, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/query";
import { Col, Row } from "react-bootstrap";
import CustomNavbar from "../../Components/CustomComponents/CustomNavbar";
import {
  useGetActiveUsersQuery,
  useGetAllUsersQuery,
} from "../../Data/services/dashboardApi";
import ChatThreads from "./ChatThreads";
import Inbox from "./Inbox";
import styles from "./styles/Layout.module.scss";

export default function DesktopLayout({ setUser, user }) {
  const [activeLink, setActiveLink] = useState("Artist");
  const [activeUsers, setActiveUsers] = useState(false);

  const { data: activeUserData, refetch: refetchActiveUser } =
    useGetActiveUsersQuery(
      activeUsers ? true : skipToken, // Only run when activeUser is true
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: allUsersData, refetch: refetchAllUsers } = useGetAllUsersQuery(
    activeUsers ? skipToken : true,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    console.log(activeUsers);
  }, [activeUsers]);

  const handleRefetch = () => {
    if (activeUsers) {
      refetchActiveUser();
    } else {
      refetchAllUsers();
    }
  };

  const handleChangeActive = (link) => {
    setActiveLink(link);
    setUser("");
  };

  return (
    <div className={styles.Layout}>
      <div className="w-100  ">
        <CustomNavbar
          activeLink={activeLink === "Users" ? "Users" : "Artist"}
          handleChangeActive={handleChangeActive}
          setActiveUsers={setActiveUsers}
        />
      </div>
      <Row className={styles.row}>
        <Col className={styles.col} md={4}>
          <ChatThreads
            user={user}
            setUser={setUser}
            activeLink={activeLink}
            activeUsers={activeUsers}
            refetch={handleRefetch}
          />
        </Col>
        <Col className={styles.col} md={8}>
          <Inbox
            setUser={setUser}
            user={user}
            activeLink={activeLink}
            activeUsers={activeUsers}
          />
        </Col>
      </Row>
    </div>
  );
}

import React, { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Button,
  CustomInput,
  Description,
  Title,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { useForgotPasswordMutation } from "../../Data/services/userAuthApi";
import { ACCOUNT_ENUM } from "./Route";

export default function ForgotPassword({ setPage }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (errors.email?.message) {
      toast.error(errors.email?.message);
    }
  }, [errors]);

  useEffect(() => {
    if (errors.email) {
      toast.error(errors.email.message);
    }
  }, [errors.email]);

  const navigate = useNavigate();
  // const isLoading = true;
  // console.log(isLoading, "isLoading");
  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const [forgetPassword, { isError, isLoading, isSuccess }] =
    useForgotPasswordMutation();

  const handleDataSubmit = async (userData) => {
    // console.log(userData);
    try {
      const response = await forgetPassword(userData);

      console.log(response);

      if (!response.error && response.data.code === 200) {
        toast.success(response?.data?.message);

        navigate(WEB_ROUTES.ACCOUNT);
      }
      if (response.error && response.error.status === 400) {
        toast.error(response?.error?.data?.message);
      }
    } catch (error) {
      console.log(error);
      if (error.data && error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error("An error occurred during login");
      }
    }
  };

  return (
    <AccountLayout
      hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      <Title text="Forgot Password" classes="mb-2" />
      <Description
        text="In order to reset your password you need to enter your registered email address."
        classes="text-center fs-7"
      />
      <Form onSubmit={handleSubmit(handleDataSubmit)}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email is required.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email address.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                classes="secondBgColor"
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text={isLoading ? <BeatLoader size={10} /> : "Continue"}
          classes={`mt-3 mb-2  ${isLoading ? "bg-dark-subtle" : "gradientBg"}`}
          disabled={isLoading}
          type="submit"
        />
      </Form>
    </AccountLayout>
  );
}

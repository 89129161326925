import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";

import toast from "react-hot-toast";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
  UnorderedList,
} from "../../Components/CustomComponents";
import { useChangePasswordMutation } from "../../Data/services/settingApi";
import styles from "./styles/ChangePassword.module.scss";

export default function ChangePassword() {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (errors.current_password) {
      toast.error(errors.current_password.message);
    } else if (errors.new_password) {
      toast.error(errors.new_password.message);
    } else if (errors.confirm_password) {
      toast.error(errors.confirm_password.message);
    }

    return () => {
      toast.dismiss();
    };
  }, [errors.current_password, errors.new_password, errors.confirm_password]);

  const newPassword = watch("new_password");

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleDataSubmission = async (userData) => {
    // console.log(userData);
    try {
      // Use unwrap() to get the response userData directly
      const response = await changePassword(userData);
      console.log(response);

      if (response.data.code === 200) {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className={styles.ChangePassword}>
      <SubTitle text="Change Password" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4"
      />
      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          <Description text="Current Password" classes="mb-1" />
          <Controller
            control={control}
            name="current_password"
            rules={{ required: "Current Password is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Current Password"
                type="password"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
              />
            )}
          />
          <Description text="Set New Password" classes="mt-3 mb-1" />
          <Controller
            control={control}
            name="new_password"
            rules={{ required: "New Password is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_password"
            rules={{ required: "Confirm Password is required." }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
              />
            )}
          />
          <UnorderedList
            lists={[
              "At least 12 characters long but 14 or more is better.",
              "A combination of uppercase letters, lowercase letters, numbers, and symbols.",
            ]}
            classes="fs-6 mt-4 mb-4 w-100 d-block"
          />

          <div className={styles.saveButton}>
            <Button
              text={isLoading ? <BeatLoader size={10} /> : "Change Password"}
              classes={`fs-6 mb-2 mt-auto mt-3 mb-2 ${
                isLoading ? "bg-dark-subtle" : "gradientBg"
              }`}
              disabled={isLoading}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

import React from "react";

import {
  Button,
  Description,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import styles from "./styles/EditProfile.module.scss";

export default function EditProfile() {
  return (
    <div className={styles.EditProfile}>
      <SubTitle text="Edit Profile" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4"
      />
      <div className={styles.form}>
        <img
          src="/images/profile.png"
          alt="User Profile"
          className={styles.userImage}
        />

        <SubTitle text="Abram Westervelt" classes="fs-5 mt-2" />
        <Description text="phillip.curtis@example.com" classes="fs-8 my-1" />
        <Description text="+84 905070017" classes="fs-8 mb-3" />
        <Input placeholder="First name" />
        <Input placeholder="Last name" />
        <Input placeholder="Email Address" />

        <Button text="Save and Continue" classes="mt-5 mb-2 fs-6 gradientBg" />
      </div>
    </div>
  );
}

import React from "react";
import { FaUserSlash } from "react-icons/fa6";
import { ImPhone } from "react-icons/im";
import { TfiEmail } from "react-icons/tfi";
import { Description, SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/Inbox.module.scss";

export default function Inbox({
  isMobile = false,
  setUser,
  user,
  activeUsers,
  activeLink,
}) {
  const handleBackButtonClick = () => {
    // setUser("");
  };
  return (
    <div className={styles.Inbox}>
      {!user ? (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <FaUserSlash color="gray" size={100} />
          <SubTitle
            text="User/Artist not selected"
            classes="gray-text w-25 fw-normal text-center my-2"
          />
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className={`${styles.image}`}>
            <img alt="" src={user.image_url} />
          </div>
          <div className="d-flex flex-column-reverse justify-content-between my-3 flex-md-row">
            <div className="d-flex flex-column w-50 ">
              <div className="d-flex flex-row ">
                <div className="text-white fs-4 fw-bold me-4">{`${user.first_name} ${user.last_name}`}</div>
                <p
                  className={` ${
                    user.user_type === "ARTIST" || activeUsers === true
                      ? styles.tags
                      : ""
                  } mt-2 mb-0 lc-1 fs-7`}
                >
                  {user.user_type === "ARTIST"
                    ? "Artist"
                    : activeUsers
                    ? "Active"
                    : null}
                </p>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className={`mt-3 ${styles.contact}`}>
                  <ImPhone color="white" size={20} />
                  <span className={`mx-3 fs-7`}>{user.mobile_no}</span>
                  <TfiEmail color="white" size={20} />
                  <span className={`mx-3 fs-7`}>{user.email}</span>
                </div>
                <div></div>
              </div>
            </div>
            {user.user_type === "ARTIST" ? (
              <div
                className={`${styles.qr} d-flex align-items-center justify-content-center `}
              >
                <img alt="" src={user.qr_code} width={40} />
              </div>
            ) : user.tags !== null ? (
              <div className="d-flex flex-row  gap-1">
                {user.tags.map((tag, index) => {
                  return (
                    <p
                      className={` ${styles.tags} mb-0  px-4 py-2 lc-1 fs-6 `}
                      style={{ height: "fit-content", width: "fit-content" }}
                      key={index}
                    >
                      {tag}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
          {user.user_type === "ARTIST" ? (
            <div>
              <div className={`${styles.about} my-2`}>
                <SubTitle text="About Artist" classes="fs-5" />
                <Description text={user.bio_description} classes="fs-7 my-2" />
              </div>
              {
                // <div className={`${styles.platformLink} my-2`}>
                //   <SubTitle text="Platform Link" classes="fs-5" />
                //   <div className={`mt-3 ${styles.link}`}>
                //     <FaLink color="white" size={20} />
                //     <a
                //       href="http://www.google.com"
                //       target="_blank"
                //       rel="noreferrer"
                //     >
                //       <span>Tap to visit the website</span>
                //     </a>
                //   </div>
                // </div>
              }
            </div>
          ) : (
            <div className={`${styles.about} my-2`}>
              <SubTitle text="Bio" classes="fs-5" />
              <Description text={user.bio_description} classes="fs-7 my-2" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

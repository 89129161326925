import React, { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import {
  Button,
  CustomInput,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { storeToken } from "../../Data/services/localStorageService";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import { ACCOUNT_ENUM } from "./Route";

export default function Login({ setPage }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (errors.email) {
      toast.error(errors.email.message);
    } else if (errors.password) {
      toast.error(errors.password.message);
    }
  }, [errors.email, errors.password]);

  // const pagename = useSelector((state) => state.pagename.pagename);

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const [loginUser, { isLoading }] = useLoginUserMutation();

  const handleLogin = async (data) => {
    data.device_type = "web";
    data.device_token = "123";

    // navigate(WEB_ROUTES.DASHBOARD);

    try {
      const response = await loginUser(data);

      console.log(response);

      if (response.data.code === 200) {
        storeToken(response.data.data.api_token);
        toast.success(response?.data?.message);
        navigate(WEB_ROUTES.DASHBOARD);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, "error");
      } else {
        toast.error("An error occurred during login", "error");
      }
    }
  };

  return (
    <AccountLayout>
      {
        // <Title text="Welcome to" classes="mb-2 fs-4" />
      }
      <SubTitle text="Admin Panel" classes="mb-2" />

      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email is required.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email address.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3 "
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                classes="secondBgColor"
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: "Password is required.",
            minLength: {
              value: 3,
              message: "Minimum 3 characters long.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                classes="secondBgColor"
                type="password"
                placeholder="Password"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text={isLoading ? <BeatLoader size={10} /> : "Login"}
          classes={`mt-3 mb-2  ${isLoading ? "bg-dark-subtle" : "gradientBg"}`}
          type="submit"
          disabled={isLoading}
        />
      </Form>
      <Description
        classes="ms-auto cursor-pointer"
        text="Forgot Password ?"
        onClick={handleForgotPasswordClick}
      />
    </AccountLayout>
  );
}

import React, { useEffect, useState } from "react";

import CustomNavbar from "../../Components/CustomComponents/CustomNavbar";
import ChatThreads from "./ChatThreads";
import Inbox from "./Inbox";
import styles from "./styles/Layout.module.scss";

export default function MobileLayout({ user = "", setUser }) {
  const [activeLink, setActiveLink] = useState("Users");
  const [activeUsers, setActiveUsers] = useState(false);

  useEffect(() => {
    console.log(activeUsers);
  }, [activeUsers]);

  const handleChangeActive = (link) => {
    // handleThreadClick();
    console.log(link);
    setActiveLink(link);
    console.log(activeLink);
  };
  return (
    <div className={styles.Layout}>
      {user ? (
        <Inbox isMobile={true} setUser={setUser} user={user} />
      ) : (
        <div>
          <CustomNavbar
            activeLink={activeLink === "Users" ? "Users" : "Artist"}
            handleChangeActive={handleChangeActive}
            setActiveUsers={setActiveUsers}
          />
          <ChatThreads setUser={setUser} user={user} activeLink={activeLink} />
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";

import styles from "./styles/ChatThreads.module.scss";

import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import BorderedCard from "../../Components/Cards/BorderedCard";
import { CardTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import { useDeleteUserMutation } from "../../Data/services/dashboardApi";

export default function ChatThreads({
  user,
  setUser,
  activeLink,
  activeUsers,
  refetch,
}) {
  const [data, setData] = useState([]);
  const [deletedData, setDeletedData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  var { data: dashboardData, loading } = useSelector(
    (state) => state.dashboard
  );
  // console.log(dashboardData);
  // console.log(loading);

  useEffect(() => {
    // console.log(activeLink);
    if (activeLink === "Users") {
      setData(dashboardData?.users);
    } else if (activeLink === "Artist") {
      setData(dashboardData?.artists);
    }
  }, [activeLink, activeUsers, dashboardData]);

  const dummyData = {
    artists: [
      {
        slug: "e9bafe37-0a62-4f55-be9b-70f9ac3a50d0",
        first_name: "test",
        last_name: "tab",
        email: "test@yopmail.com",
        user_type: "ARTIST",
        mobile_no: "+11234422111",
        bio_description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        image_url: "https://api.pickntip.net/user-placeholder.jpeg",
        qr_code: "https://api.pickntip.net/1726122784234.jpg",
        tags: null,
      },
      {
        slug: "b1562ed4-b89a-4729-863e-638178dbe7cb",
        first_name: "Brad",
        last_name: "Broussard ",
        email: "pickntip13@gmail.com",
        user_type: "ARTIST",
        mobile_no: "+13373090548",
        bio_description: "singing guitarist",
        image_url: "https://api.pickntip.net/1726178623098.jpg",
        qr_code: "https://api.pickntip.net/1726178560776.jpg",
        tags: "all types of misic",
      },
      {
        slug: "e2f3667b-a056-49e6-9e37-8e8a0156a564",
        first_name: "artist",
        last_name: "one",
        email: "a1@yopmail.com",
        user_type: "ARTIST",
        mobile_no: "+14324324323",
        bio_description: null,
        image_url: "https://api.pickntip.net/user-placeholder.jpeg",
        qr_code: "https://api.pickntip.net/1726499008248.jpg",
        tags: null,
      },
      {
        slug: "440aff1b-799e-4bb5-a22c-c49b6098b98a",
        first_name: "Test",
        last_name: "abc",
        email: "Abc@yopmail.com",
        user_type: "ARTIST",
        mobile_no: "+11234567890",
        bio_description: null,
        image_url: "https://api.pickntip.net/user-placeholder.jpeg",
        qr_code: "https://api.pickntip.net/1726729907544.jpg",
        tags: null,
      },
    ],
    users: [
      {
        slug: "d53d2c67-846a-4ec4-9c15-e0bc77243799",
        first_name: "test",
        last_name: "user",
        email: "user@yopmail.com",
        user_type: "USER",
        mobile_no: "+12334324322",
        bio_description: "this is",
        image_url: "https://api.pickntip.net/user-placeholder.jpeg",
        qr_code: "https://api.pickntip.net/user-placeholder.jpeg",
        tags: ["art", "Fitness", "Fashion", "Music"],
      },
      {
        slug: "a156ad5a-1c46-4d49-908d-09183e4bfd14",
        first_name: "user",
        last_name: "two",
        email: "user2@yopmail.com",
        user_type: "USER",
        mobile_no: "+12342342332",
        bio_description: null,
        image_url: "https://api.pickntip.net/user-placeholder.jpeg",
        qr_code: "https://api.pickntip.net/user-placeholder.jpeg",
        tags: null,
      },
      {
        slug: "531b34e5-8887-4ed5-976f-41b9bcb3fa2e",
        first_name: "Jhon Doe",
        last_name: null,
        email: "jhondoe506369@gmail.com",
        user_type: "USER",
        mobile_no: null,
        bio_description: null,
        image_url: "https://api.pickntip.net/user-placeholder.jpeg",
        qr_code: "https://api.pickntip.net/user-placeholder.jpeg",
        tags: null,
      },
    ],
  };

  const handleThreadClick = async (item) => {
    // setUser("123");
    // console.log(item);
    setUser(item);
    console.log(user);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setDeletedData("");
  };

  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();

  const handleDelete = async () => {
    // console.log(item);
    console.log(deletedData);

    const userSlug = deletedData;

    try {
      const response = await deleteUser(userSlug);

      console.log(response.data);

      if (response.data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
      // Toast(error.message, "error");
      toast.error("Something went wrong.");
    }
  };

  return (
    <div className={styles.ChatThreads}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={deletedData}
          onDelete={handleDelete}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}
      <div className={styles.threads}>
        {loading
          ? Array.from({ length: 3 }).map((item, index) => {
              return (
                <div
                  className={`rounded-3 mainBgColor d-block w-100 my-2 p-2`}
                  key={index}
                >
                  <ShimmerCategoryItem
                    hasImage
                    imageType="circular"
                    imageWidth={60}
                    imageHeight={60}
                    title
                    // text
                  />
                </div>
              );
            })
          : data?.map((usr, index) => {
              return (
                <div key={index}>
                  <BorderedCard
                    classes={`my-2 overflow-visible ${
                      usr?.slug === user?.slug ? styles.active : ""
                    }`}
                  >
                    <div className={`${styles.thread} `}>
                      <div
                        className="d-flex flex-row"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleThreadClick(usr)}
                      >
                        <img
                          className={styles.userImage}
                          src={"/images/profile.png"}
                          alt="User Profile"
                        />
                        <div className={styles.userInfo}>
                          <div className={styles.nameContainer}>
                            <CardTitle
                              text={`${usr.first_name} ${usr.last_name}`}
                              classes="fs-6 fw-bold lc-1"
                            />
                            {
                              //    <span className={styles.indicator}></span>
                            }
                          </div>
                          <p
                            className={` ${
                              usr.user_type === "ARTIST" || activeUsers === true
                                ? styles.tags
                                : styles.email
                            } mt-2 mb-0 lc-1`}
                          >
                            {usr.user_type === "ARTIST"
                              ? "Artist"
                              : activeUsers
                              ? "Active"
                              : usr.email}
                          </p>
                        </div>
                      </div>
                      <div
                        className={styles.deleteIcon}
                        role="button"
                        onClick={() => {
                          console.log(usr.slug);
                          setDeletedData(usr.slug);
                          handleDeleteModalOpen();
                          // handleDelete(usr.slug)
                        }}
                      >
                        <img src="/svg/Delete.svg" alt="" width={22} />
                      </div>
                    </div>
                  </BorderedCard>
                </div>
              );
            })}
      </div>
    </div>
  );
}

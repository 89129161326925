/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { STRING } from '../../../Constant/StringConstant';
import { Checbox } from "../CustomComponents/index";
import style from "./customNavbar.module.scss";

const CustomNavbar = ({ activeLink, handleChangeActive, setActiveUsers }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-2">
      <ul className="d-flex justify-content-between slide-in-right-fast">
        <li className="scale-in-center-slow ">
          <a
            href="#"
            className={
              activeLink === "Users" ? `${style.active} gradientBg` : ""
            }
            onClick={() => handleChangeActive("Users")}
          >
            {"Users"}
          </a>
        </li>
        <li className="scale-in-center-slow ms-1">
          <a
            href="#"
            className={
              activeLink === "Artist" ? `${style.active} gradientBg` : ""
            }
            onClick={() => handleChangeActive("Artist")}
          >
            {"Artist"}
          </a>
        </li>
      </ul>
      {activeLink === "Users" ? (
        <Checbox
          label="View Active Users"
          classes="text-white"
          onChange={(e) => setActiveUsers(e.target.checked)}
        />
      ) : null}
    </div>
  );
};

export default CustomNavbar;
